<template lang="">
  <div class="flex justify-center items-center relative ml-5 mr-2" v-click-outside="closeDropdown">
    <button type="button" class="element-btn bg-navy size-xs round" @click="isOpen = !isOpen">
      <i class="icon-img ic-menu-filter"></i>
      필터
      <!-- <i class="icon-img ic-filter-delete" @click="resetFilter()"></i> -->
    </button>
    <!-- <button type="button" class="util-item" @click="isOpen = !isOpen">
      <i class="ic-top-navi-users"></i>
      <span class="for-a11y">내정보</span>
      <span class="text-white mx-2">{{getCurrentUser.ur.userID}} ({{managerRole[getUserRole]}})</span>
    </button> -->
    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"></button>
    
    <div v-if="isOpen" class="absolute top-10 right-1 py-2 mt-2 rounded-lg border-gray-900 bg-white shadow-xl min-w-max px-5" style="z-index:10000">
      <div class="element-dropdown-layer-inner">
        <div class="element-dropdown-button check">
          <div class="element-checkbox checkbox-text">
            <input class="radio-input" type="checkbox" id="checkbox1" v-model="deviceFilter.normal" @change="chgFilterCheck()">
            <label class="checkbox-label" for="checkbox1">정상</label>
          </div>
        </div>

        <div class="element-dropdown-button check">
          <div class="element-checkbox checkbox-text">
            <input class="radio-input" type="checkbox" id="checkbox2" v-model="deviceFilter.detect" @change="chgFilterCheck()">
            <label class="checkbox-label" for="checkbox2">탐지됨</label>
          </div>
        </div>

        <div class="element-dropdown-button check">
          <div class="element-checkbox checkbox-text">
            <input class="radio-input" type="checkbox" id="checkbox3" v-model="deviceFilter.offline" @change="chgFilterCheck()">
            <label class="checkbox-label" for="checkbox3">오프라인</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
  },
  directives: {
    ClickOutside
  },
  created () {
    this.deviceFilter = JSON.parse(this.getDeviceFilterStatus)
  },
  computed: {
    ...mapGetters(["getUserRole","getCompanyGuid","getCurrentUser","getDeviceFilterStatus"])
  },
  data () {
    return {
      isOpen: false,
      deviceFilter: {},
    }
  },
  methods: {
    ...mapActions(["chgDeviceFilterStatus"]),
    closeDropdown () {
      this.isOpen = false
    },
    chgFilterCheck () {
      this.chgDeviceFilterStatus(JSON.stringify(this.deviceFilter))
    },
    resetFilter () {
      this.chgDeviceFilterStatus(JSON.stringify({ normal: true, detect: true, offline: true }))
    }
  }
}
</script>
<style lang="">
</style>